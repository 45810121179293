import React,{useState,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'

const AnnouncementModal = () => {

        const [announcement, setAnnouncement] = useState([]);
        const [show, setShow] = useState(false);
        // const [loading,setLoading] = useState(false);

        // const imagePath = 'https://erp.makemoneyormiles.com/storage/announcements/';
        const imagePath = 'http://localhost:8000/storage/announcements/';
        const API_URL = process.env.REACT_APP_API_URL;
        const GET_ANNOUNCEMENT_URL = `${API_URL}/get-announcement`;

        
    
        useEffect(() => {
            const fetchAnnouncements = async () => {
                try {
                    // setLoading(true);
                    const response = await axios.get(GET_ANNOUNCEMENT_URL);
                    
                    // Check if the modal has been shown today
                    if(response.data.data != null){
                        const lastShownDate = localStorage.getItem('announcementModalLastShown');
                        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
                        if(lastShownDate){
                            if(lastShownDate != today){
                            setAnnouncement(response.data.data);
                            setShow(true);
                            localStorage.setItem('announcementModalLastShown', today);
                            }else{
                                setShow(false);
                            }
                        }
                        else{
                            setAnnouncement(response.data.data);
                            setShow(true);
                            localStorage.setItem('announcementModalLastShown', today);
                        }
                        // setAnnouncement(response.data.data);
                        // setShow(true);
                    }
                    
    
                    
                } catch (error) {
                    console.error('Error fetching announcements:', error); // Log any errors
                }
            };
            fetchAnnouncements();
        }, []);
    
        const handleClose = () => setShow(false);

        const formatDate = (dateString) => {
            if (!dateString) return '';
            const [year, month, day] = dateString.split('-');
            const date = new Date(year, month - 1, day); // Adjust for zero-indexed months
            const options = { month: 'short', day: 'numeric' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        };

        const modalStyle = {
            backgroundImage: announcement.background_image && announcement.background_only != 1 ? `url(${imagePath}${announcement.background_image})` : 'none',
            backgroundColor: announcement.background_image ? 'transparent' : announcement.background_color,
            height: announcement ? '100%' : '400px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding:0,
            borderRadius:'30px',
        };


  return (
    <>
            <Modal dialogClassName='announcement-modal' size="xl" show={show} onHide={handleClose} centered>
                <a onClick={handleClose} className="announcement-modal-close" style={{cursor:'pointer'}}>X</a>
                <Modal.Body style={modalStyle}>
                    {announcement.background_only != 1 ? (
                        <div className="row">
                        <div className="col-md-6 text-center">
                        {announcement.primary_image && (
                            <img 
                                src={`${imagePath}${announcement.primary_image}`} 
                                alt="Primary" 
                                style={{ width: '100%', margin:'auto',borderRadius:'30px'}} 
                            />
                        )}
                        </div>
                        <div className="col-md-6" style={{padding:'50px'}}>
                        <h1 style={{fontSize: announcement.title_fontsize+'rem',color:announcement.text_color ? announcement.text_color : '#000000'}}>{announcement.title}</h1>
                        <h5 className='mt-3 mb-5' style={{fontSize:announcement.desc_fontsize+'rem',color:announcement.text_color ? announcement.text_color : '#000000'}}>{announcement.description}</h5>
                        {announcement.dates_visible == 1 && (
                            <>
                            (<div className="announcement-dates mt-5 d-flex align-items-center justify-content-center">
                            <strong style={{fontSize:'20px',fontWeight:'500'}}>Starting From</strong>
                            <h3 className='mx-3' style={{fontSize:'25px',fontWeight:'600'}}>{formatDate(announcement.start_date)}</h3>
                        </div>
                        <div className="announcement-dates mt-5 d-flex align-items-center justify-content-center">
                            <strong style={{fontSize:'20px',fontWeight:'500'}}>Ends On</strong>
                            <h3 className='mx-3' style={{fontSize:'25px',fontWeight:'600'}}>{formatDate(announcement.end_date)}</h3>
                        </div>)
                            </>
                        )}
                        </div>
                    </div>
                    ) : (
                        <img 
                            src={`${imagePath}${announcement.background_image}`}
                            style={{ width: '100%',height:'auto',maxHeight: '800px', borderRadius:'30px', objectFit: 'cover',}} 
                        />
                    )}    
                </Modal.Body>
            </Modal>
        </>
  )
}

export default AnnouncementModal